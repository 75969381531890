import React, { useState } from "react";
import banner from "../../assets/images/bg.jpg";
import SearchContent from "../../components/SearchContent";
import GrammarFormsService from "../../services/GrammarForms";
import config from "../../config";

function Home() {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  function onSearch(term) {
    setSearchTerm(term);
    setLoading(true);
    setNoResults(false);
    setItems([]);
    GrammarFormsService.search(term).then((items) => {
      setLoading(false);
      if (items.length === 0) {
        setNoResults(true);
      } else {
        setItems(items);
      }
    });
  }

  return (
    <div className="kgf-Home">
      <img className="kgf-Home-bg" src={banner} alt="" />
      <div className="kgf-Home-content">
        <h1 className="mb-2 text-4xl font-bold">
          Welcome to the Dictionary of Korean Grammatical Forms
        </h1>
        <h2 className="mb-2 text-xl">
          Please select one of the grammatical categories in the drop box below,
          or navigate with the links provided.
        </h2>
      </div>
      <p className="kgf-Home-text">
        We are continuously fine-tuning the data for the Dictionary of Korean
        Grammatical Forms, and the dictionary entries as they display now are
        still experimental. We would welcome feedback from users about any and
        all aspects of the on-line grammar dictionary. Please direct feedback to
        Dr. Ross King at:{" "}
        <a href="mailto:ross.king@ubc.ca" className="text-blue-600">ross.king(at)ubc.ca</a>.
        <br />
        <br />
        <i style={{ fontSize: 14 }}>
          Didn't find the form you were looking for? If you have a form or
          example that you think deserves an entry in the Dictionary of Korean
          Grammatical Forms, <br />
          please{" "}
          <a
            href="mailto:ross.king@ubc.ca?subject=Requesting new example for Korean Grammatical Forms"
            className="text-blue-600"
          >
            click here
          </a>{" "}
          to submit your example or query.
        </i>
        <br />
        <br />
        {config.CATEGORIES.map((c) => (
          <span key={c} className="kgf-Home-term" onClick={() => onSearch(c)}>
            {c}
          </span>
        ))}
        {searchTerm && (
          <div className="kgf-Home-quickSearch">
            {!noResults ? (
              <>
                {!!items.length && (
                  <>
                    <span className="kgf-Home-searchItemsCount">
                      There are {items.length} entries found for "{searchTerm}".
                    </span>
                    <br />
                    <br />
                  </>
                )}
                <SearchContent items={items} isLoading={isLoading} />
              </>
            ) : (
              <span>No results found for "{searchTerm}"</span>
            )}
          </div>
        )}
      </p>
    </div>
  );
}

export default Home;
