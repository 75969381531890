import React from "react";

export default function About() {
  return (
    <div className="max-w-5xl mx-auto px-6 py-10">
      <div className="space-y-10">
        <section>
          <h3 className="text-2xl font-semibold mb-4">About</h3>
          <p className="text-gray-700 leading-relaxed">
            This “Korean Grammatical Forms” website has its origins in the
            grammatical notations developed for UBC’s KORN 410 “Modern Korean
            Short Fiction” over many years by Ross King working in conjunction
            with graduate students and advanced undergraduate research
            assistants under the auspices of UBC’s WorkLearn program. Thus, the
            site focuses primarily on endings and particles that learners would
            not normally encounter in their first three years of Korean courses
            at university in North America, and the focus is on literary prose.
            All examples are curated from the short stories featured in the KORN
            410 course. The site is not currently being worked on or maintained
            actively, but if and when appropriate research funds should
            materialize in future, the intention is to return to it, add more
            stories, examples, and forms, and enhance the user interface. But
            even in its current state, it is frequently more useful than many of
            the print resources currently available.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Supported for a few years by UBC’s Teaching and Learning Enhancement
            Fund is hereby gratefully acknowledged, as are the contributions of
            students who worked on the annotations like Cindy Chen, Jane Suh,
            Theresa Joo, Frank Rausch, Dafna Zur and Si Nae Park.
          </p>
          <p className="text-gray-700 leading-relaxed">
            The many twists and turns in site design and development (including
            work on the separate but closely related KORN 410 site, which for
            reasons of copyright is currently kept behind a firewall) have been
            directed and implemented over the years by UBC students Armaan
            Dhanji, Dawn Kim, Amy George, Alfred Hong, Hyesun An, Max Cao, Minta
            Sintaha, and most recently Porya Dashtipour. Needless to say, the
            name “Korean Grammatical Forms” is by way of homage to Canadian
            missionary to Korea James Scarth Gale (1863-1937) and his pioneering
            manual of the same title, printed in three editions (1894, 1903,
            1916), and with the parallel Korean title 사과지남 (辭課指南).
          </p>
        </section>
      </div>
    </div>
  );
}
