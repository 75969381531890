import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Login from "../Login";
import EntryModal from "../EntryModal";
import GrammarFormsService from "../../services/GrammarForms";

function Header({ currentUser, onSetCurrentUser }) {
  const [openModal, setModalOpen] = useState(false);

  function onAdd(data) {
    GrammarFormsService.create(data).then((resp) => {
      console.log(resp);
      setModalOpen(false);
    });
  }

  return (
    <Navbar bg="dark" data-bs-theme="dark" style={{ zIndex: 999 }}>
      <Container fluid>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Nav>
            <Nav.Link href="/" active={location.pathname === "/"}>
              Home
            </Nav.Link>
            <Nav.Link href="/about" active={location.pathname === "/about"}>
              About
            </Nav.Link>
          </Nav>

          <Nav style={{ marginBottom: -6 }}>
            {currentUser?.isAdmin && (
              <Nav.Item>
                <div className="kgf-Header-add">
                  <Tooltip title="Add New Entry">
                    <AddIcon onClick={() => setModalOpen(true)} />
                  </Tooltip>
                </div>
              </Nav.Item>
            )}
            <Nav.Item>
              <Login
                currentUser={currentUser}
                onSetCurrentUser={onSetCurrentUser}
              />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>

        <EntryModal
          open={openModal}
          text="Add"
          onClose={() => setModalOpen(false)}
          onSubmit={onAdd}
        />
      </Container>
    </Navbar>
  );
}

export default Header;
