import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Card, CardContent } from '@mui/material';
import loader from '../../assets/images/loader.gif';
import './_index.scss';


function SearchContent({ isLoading, items }) {
    return (
        <div className="kgf-SearchContent">
            {isLoading && <img src={loader} width="150"/>}
            {(items || []).map((item, i) => (
                <Card className="kgf-SearchContent-card" variant="outlined" key={item + i}>
                <React.Fragment>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            <a href={`/entry/${item._id}`}>{item.entryName}</a>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <span dangerouslySetInnerHTML={{__html: item.typicalExample.replace(item.entryName, '<b>' + item.entryName + '</b>') }} />
                        </Typography>
                        <Typography variant="body2">
                            <div className="kgf-SearchContent-category">{item.categoryKorean} {item.categoryEnglish}</div>
                        </Typography>
                    </CardContent>
                </React.Fragment>
            </Card>
        ))}
        </div>
    );
}

SearchContent.propTypes = {
    items: PropTypes.array
};

export default SearchContent;
